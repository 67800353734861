.input-otp {
  justify-content: space-between !important;
}

.input-otp__field {
  background-color: var(--primary-light-disable) !important;
  border-color: var(--primary-light-disable) !important;
}

/* CountDown */
.ant-statistic .ant-statistic-content {
  font-size: 18px;
  color: var(--primary-light);
  font-weight: 600;
  text-align: center;
}

/* reset Password */
.reset-password input {
  background-color: var(--primary-light-disable) !important;
}
