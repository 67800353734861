/* add form styles */
.add-form label.ant-form-item-required {
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-normal) !important;
}

.add-form label {
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-normal) !important;
}

/* Job type page-unfilled button */
.jobType-btn {
  border: 1px solid var(--primary-light);
}

/* edit form styles */
.edit-form label.ant-form-item-required {
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-normal) !important;
}

.edit-form label {
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-normal) !important;
}

/* view form styles */
.view-form label {
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-normal) !important;
}

.view-form .status label {
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-medium) !important;
}

.view-form .ant-tag {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
}

/* JobType Page : modal styles */
.ant-modal-footer {
  display: none;
}

.ant-modal-close {
  display: none;
}

.drawer-form
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.drawer-form
  label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block !important;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.drawer-form .ant-form-vertical .ant-form-item-label > label::after {
  visibility: visible;
}

/* job type page */
.dropdown-answers .ant-form-item {
  margin-bottom: 0 !important;
}

.question-inputField-selector .ant-form-item {
  width: 60% !important;
}

.question-inputField-selector .ant-select-selector {
  border: 1px solid var(--primary-light) !important;
}

/* file bulk upload */
.file-upload .ant-upload-list {
  display: none;
}

.admin-page .view-form label {
  font-size: 14px !important;
  font-weight: 400 !important;
}

/* add permission drawer label */
.drawer-form
  .add-permission
  .ant-form-vertical
  .ant-form-item-label
  label::after {
  visibility: hidden !important;
}
/* dashboard location table pagination */
.dashboard .ant-table-wrapper .ant-table-pagination {
  display: none !important;
}

/* view image */

.view-drawer .ant-upload-list-item-actions button {
  display: none !important;
}

/* terms page drawers */
.drawer-form .label-after .ant-form-item-label > label::after {
  visibility: hidden !important;
}

/* common configuration page */
.c-configuration
  :where(.css-dev-only-do-not-override-p476dz).ant-form-item
  .ant-form-item-label {
  padding-right: 10px !important;
}

.table-status-button
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white !important;
}

.tradie-block-button
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white !important;
}

.tradie-block-button
  :where(.css-dev-only-do-not-override-p476dz).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):active {
  color: white !important;
}

/* image width height change */
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 250px !important;
  height: 200px !important;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  width: 250px !important;
  height: 200px !important;
}

/* login page input :focus-within*/
:where(
    .css-dev-only-do-not-override-1e5s0jk
  ).ant-input-affix-wrapper:focus-within {
  border-color: var(--primary-light) !important;
  box-shadow: var(--primary-light) !important;
}

