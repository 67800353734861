@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size-base: 16px;
  --font-size-heading: 24px;
  --font-size-subheading: 18px;
  --font-size-small: 14px;

  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;

  /* button bg active */
  --primary-light: #2f2f2f;
  /* button bg disable */
  --primary-light-disable: #5f5f5f;
  /* text color */
  --secondary-color: #404040;
  /* delete button */
  --primary-red: #c62828;
  /* error worning msg */
  --secondary-red: #cc4c24;

  --primary-color: #ffcb45;
  /* --secondary-light: #f4f6f7; */
  --secondary-light: #f3f2f2;
  --secondary-dark-shade: #e0e0e0;
  --secondary-white: #ffffff;

  /* dropdown background */
  --secondary-light-shadow: #b8b8b8;

  --status-active: #ffcb45;
  --status-inactive: #000000;
}

@media (max-width: 425) {
  .sider {
    position: absolute !important;
    height: 100vh;
    z-index: 999;
  }
}

/* login page form styles */
.login-form label {
  font-size: var(--font-size-subheading) !important;
}

.login-form-desktop .login-form .ant-form-item .ant-form-item-explain-error {
  color: var(--primary-color) !important;
}

.login-form-mobile .login-form .ant-form-item .ant-form-item-explain-error {
  color: #a77802 !important;
}

.login-page-password input {
  background-color: var(--primary-light-disable) !important;
}

.login-page-password :where(.css-dev-only-do-not-override-p476dz).ant-input {
  color: var(--secondary-dark-shade) !important;
}

.forgot-password
  :where(.css-dev-only-do-not-override-p476dz).ant-btn-text:not(:disabled):not(
    .ant-btn-disabled
  ):active,
.forgot-password
  :where(.css-dev-only-do-not-override-p476dz).ant-btn-text:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background-color: transparent !important;
}

.forgot-password
  :where(.css-dev-only-do-not-override-p476dz).ant-btn:not(
    :disabled
  ):focus-visible {
  outline: none !important;
}

/* login checkbox color */
.login-form .ant-checkbox-inner {
  background-color: var(--primary-light-disable) !important;
  border-color: var(--primary-light-disable) !important;
}
.login-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-light-disable) !important;
  border-color: var(--primary-light-disable) !important;
}
.login-form
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: var(--primary-light-disable) !important;
}
.login-form .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
  border-color: var(--primary-light-disable) !important;
}
.login-form
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-p476dz).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: var(--primary-light-disable) !important;
}

.login-form-mobile label {
  color: var(--primary-light) !important;
}

.login-form-desktop label {
  color: var(--secondary-white) !important;
}

.login-email
  label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.login-password
  label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.login-form .ant-btn-primary:disabled {
  background: #aab8c2;
  color: rgb(99, 98, 98);
  font-weight: 600;
  border-color: #aab8c2 !important;
}

/* sidebar item styles */
.ant-menu-item-selected {
  background-color: var(--primary-light) !important;
  color: var(--primary-color) !important;
}

.ant-menu-item {
  font-weight: var(--font-weight-bold);
}

.ant-menu-item-active {
  background-color: var(--primary-light) !important;
  color: var(--primary-color) !important;
}

.ant-menu-submenu {
  font-weight: var(--font-weight-bold);
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background-color: var(--primary-light) !important;
  color: var(--primary-color) !important;
}

/* .ant-layout-sider-children ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}

.ant-layout-sider-children ul li:last-child {
  margin-top: auto;
} */

/* drawer's switch styles */
.ant-switch {
  background: rgba(0, 0, 0, 0.45);
}

/* page's tables styles */
tr.ant-table-cell {
  color: var(--secondary-color) !important;
  font-weight: var(--font-weight-medium) !important;
}

td.ant-table-cell {
  color: var(--secondary-color) !important;
  font-weight: var(--font-weight-normal) !important;
  font-size: var(--font-size-base) !important;
}

/* drawer's common styles:image upload box styles  */
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 120px;
  height: 120px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  width: 120px;
  height: 120px;
}

/* drawer's common styles: styles  */
.ant-drawer-header {
  display: none !important;
}

.ant-drawer-body {
  padding-top: 0 !important;
}

/* tabele pagination common styles */
.ant-pagination-item-active {
  border-radius: 15px !important;
  background-color: var(--primary-light) !important;
}

.ant-pagination-item-active a {
  color: var(--secondary-white) !important;
}

/* /// */

.ant-btn-primary:not(:disabled):hover {
  color: var(--secondary-white);
  background-color: var(--primary-light);
}

.ant-btn-primary {
  background-color: var(--primary-light);
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}

/* .ant-pagination .ant-select-selector {
  display: none !important;
} */

.dashboard .ant-select-selector {
  border-radius: 0 30px 30px 0 !important;
  font-weight: 500 !important;
  background-color: var(--secondary-light) !important;
  border-style: none !important;
}

@media (min-width: 640px) {
  .dashboard .ant-select-selector {
    font-size: 16px !important;
  }
}

@media (max-width: 639px) {
  .dashboard .ant-select-selector {
    font-size: 10px !important;
  }
}

.dashboard tr {
  background-color: var(--secondary-light) !important;
}

.ant-pagination .ant-pagination-item-active {
  border-color: var(--primary-light) !important;
}

.password .ant-input {
  background-color: #dddfe0;
}

.player div {
  border-radius: 30px !important;
}

.sider-scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.sider-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
